.footer_box {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 2.4rem;
  background-color: #f4f3f3;
  padding-bottom: 2.4rem;

  a {
    &:hover {
      color: #aaaaaa;
    }
  }

  .footer_contact,
  .footer_product,
  .footer_policy,
  .footer_connect {
    flex: 1;
    text-align: left;
    padding-left: 1.2rem;
    font-size: 1.5rem;
    margin-top: 1.8rem;
    @media screen and (max-width: 760px) {
      flex-basis: 100%;
    }
  }

  .footer_contact {
    p {
      margin-top: 0.6rem;
    }
    img {
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      margin-top: 1rem;
    }
  }

  .footer_product {
    min-width: 20rem;

    .footer_product_Item_box {
      display: flex;
      flex-direction: column;
      .footer_product_item {
        margin-top: 1rem;
        text-decoration: none;
      }

      p {
        margin-top: 1rem;
      }
    }
  }

  .footer_connect {
    .footer_connect_social {
      margin: 1.2rem 0;
      display: flex;
      justify-content: flex-start;
      a {
        margin-right: 0.6rem;
        img {
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
        }
      }
    }
  }

  .footer_policy {
    min-width: 20rem;
    p {
      margin-top: 1rem;

      a {
        text-decoration: none;
      }
    }
  }
}

.bac {
  background-color: #edebeb;
  font-size: 1.2rem;
  padding: 0.4rem 0;
}
