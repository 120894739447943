.introduce_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: 1.6rem;
  text-align: left;
  margin: 3.6rem 0;
  padding: 1.2rem 2.4rem;
  background-color: #f4f3f3;

  .introduce {
    flex: 1;
    padding-right: 2.4rem;

    @media screen and (max-width: 760px) {
      padding-right: 0;
    }

    h2 {
      font-family: 'Great Vibes', cursive;
      color: var(--color_main);
    }

    h1 {
      margin: 1.2rem 0;
    }
    p {
      line-height: 2.6rem;
    }

    .introduce_seo {
      border-radius: 10px;
      border: 1.4px solid var(--color_main);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      margin-top: 1.2rem;

      padding: 1.2rem;
    }

    .introduce_experience {
      display: flex;
      justify-content: center;
      align-items: center;

      .introduce_experience_1,
      .introduce_experience_2 {
        flex: 1;
        margin: 1.2rem 0 1.2rem 1.6rem;

        h3 {
          font-size: 3rem;
          animation: moveUp 2s ease-out 1s infinite alternate;
        }

        @keyframes moveUp {
          from {
            transform: translateY(0.4rem);
          }

          to {
            transform: translateY(0rem);
          }
        }
      }
    }

    .introduce_content {
      text-align: justify;
    }
  }

  .introduce_img {
    flex: 1;
    min-width: 34rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
