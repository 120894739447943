.form_mail_div {
  padding: 1.2rem 0;
  .form_mail_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form_mail {
      width: 50%;
      background-color: #f4f3f3;
      margin-top: 1.2rem;
      padding: 1.9rem;
      border-radius: 0.6rem;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      @media screen and (max-width: 760px) {
        width: 86%;
      }
      text-align: left !important;

      .row {
        margin-bottom: 2rem;
        display: flex;

        .col_left {
          flex: 1;
        }

        .col_right {
          flex: 2;
          .input {
            width: 100%;
            padding: 0.6rem;
            font-size: 16px;
            // @media screen and (-webkit-min-device-pixel-ratio: 0) {
            //   font-size: 16px;
            // }
          }

          .textarea {
            resize: none;
          }
        }
      }

      .row:last-child {
        margin-bottom: 0;

        .noti {
          margin-top: 1.2rem;
          color: #2ed555;
        }

        .noti_error {
          margin-top: 1.2rem;
          color: #d02525;
        }
      }
    }

    .buttom_form_mail {
      width: 6rem;
      height: 3.6rem;
      background-color: #163244;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      border-radius: 10px;
      -webkit-tap-highlight-color: transparent; //bỏ nền xanh khi nhấn vào trên mobile

      &:hover {
        background-color: #aaaaaa;
      }
    }
  }
}
