.nhom_duc_bmt {
  text-align: left;
  padding: 2.4rem 4.8rem 0 4.8rem;

  .nhom_duc_bmt_title {
    p {
      font-size: 1.1rem;
      line-height: 2.8rem;
    }
  }

  .nhom_duc_bmt_content {
    margin-top: 4.8rem;
    line-height: 2.8rem;

    p,
    i {
      font-size: 1.8rem;
      text-align: justify;
    }

    .img_box {
      text-align: center;
      margin: 1.2rem 0;
      img {
        width: 50%;
        @media screen and (max-width: 760px) {
          width: 80%;
        }
        border: 0.1rem solid var(--color_main);
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      }
    }

    .doan_noi_bat {
      border-left: 4px solid #241455;
      padding: 6px 4%;
      margin: 10px 0px 10px 0px;
      background: rgb(244, 243, 243);
    }
  }
}
