.categories_div {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin: 2.4rem;

  .category {
    .category_name {
      position: relative;
      p {
        margin: 1.2rem 0;
        padding: 1.2rem 0 1.2rem 2.4rem;
        background-color: #f4f3f3;
        position: relative;

        &::before {
          content: '';
          height: 100%;
          width: 0.8rem;
          background-color: var(--color_main);
          position: absolute;
          top: 0;
          left: 0;
        }

        // &::after {
        //   font-family: 'Font Awesome 5 Free';
        //   font-weight: 900;
        //   content: 'Xem thêm  \f105\f105';
        //   font-size: 1rem;
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   margin: 1.8rem 0.4rem;
        //   text-align: center;
        //   opacity: 0.5;
        //   cursor: pointer;
        // }
      }

      div {
        font-weight: 900;
        font-size: 1.2rem;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        margin: 1.8rem 0.4rem;
        text-align: center;
        opacity: 0.7;
        cursor: pointer;

        &:hover {
          border-bottom: 2px solid black;
        }
      }
    }

    .slider_box {
      margin-right: 3.6rem;
      cursor: grab;

      .div_img {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.4rem 0;
          font-size: 1.4rem;
          color: #807c7c;
          margin-right: 3rem;
        }
      }

      .img_slider {
        width: 90% !important;
        height: 20rem !important;
        @media screen and (max-width: 760px) {
          height: 10rem !important;
        }
      }

      .slick-next {
        &::before {
          color: #f4f3f3 !important;
          font-size: 3.6rem !important;
          position: absolute !important;
          left: 0 !important;
          top: 0 !important;
        }

        &:hover:before {
          color: rgb(138, 138, 143) !important;
        }
      }
    }
  }
}
