.contact_box {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  font-size: 1.6rem;
  background-color: transparent !important;
  z-index: 1000 !important;

  a {
    background-color: transparent !important;
    i {
      color: #6cea67 !important;
      font-size: 2.4rem;
      animation: zoom_phone 2s linear 1s infinite;
      transform: rotate(-45deg);

      @keyframes zoom_phone {
        from {
          scale: 0.8;
        }
        to {
          scale: 1.1;
        }
      }
    }
  }

  p {
    background-color: transparent !important;
    color: #6cea67 !important;
    font-size: 0.8rem;
  }
}
