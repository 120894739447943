.product_list_box {
  img {
    width: 100%;
    height: 16rem;
    @media screen and (max-width: 760px) {
      height: 10rem !important;
    }
  }

  .product_list_col {
    padding: 2.4rem;
    cursor: pointer;
  }

  h5 {
    margin: 2.4rem;
    padding: 1.2rem 0 1.2rem 2.4rem;
    background-color: #f4f3f3;
    position: relative;
    text-align: left;
    font-size: 1.8rem;
    text-transform: uppercase;

    &::before {
      content: '';
      height: 100%;
      width: 0.8rem;
      // background-color: #241455;
      background-color: var(--color_main);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .description,
  .price {
    text-align: justify;
    padding: 2.4rem 6rem;
    // font-size: 1.8rem;
    line-height: 2.8rem;

    h1 {
      margin-bottom: 1.2rem;
      line-height: 3.8rem;
    }
  }

  .price {
    p {
      margin-bottom: 1.2rem;
    }
    .dependent {
      margin-left: 1.4rem;
      margin-bottom: 1.2rem;
    }
  }

  .product_add {
    padding: 0 2.4rem;
    .product_add_text {
      background-color: rgba(22, 50, 68, 0.8);
      padding: 0.6rem;
      color: #fff;
      font-size: 1.6rem;
      // font-weight: bold;
    }
  }
}
