.policy_box {
  h1 {
    margin: 2.4rem;
  }

  p {
    text-align: justify;
    padding: 0 4.8rem;
    line-height: 2.4rem;
  }
}
