.post_box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2.4rem;
  // margin-bottom: 2.4rem;

  .news,
  .video {
    flex: 1;
    text-align: left;
    font-size: 1.6rem;
    min-width: 20rem;
    margin: 2.4rem 0;

    h2 {
      font-size: 2.4rem;
      border-bottom: 0.4rem solid #241455;
      width: 30%;
      margin-bottom: 1.2rem;
    }

    h5,
    button {
      margin: 1.2rem auto;
    }
  }

  .news {
    img {
      width: 60%;
      height: 50%;
    }
    .news_control {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;

      .news_control_button {
        flex: 1;
        text-align: left;
      }

      .news_control_span {
        flex: 1;
        text-align: left;
        font-size: 1.4rem;

        span {
          // border-bottom: 1px solid var(--color_main);
          cursor: pointer;

          &:hover {
            color: #647681;
            border-bottom: 1px solid #647681;
          }
        }

        i {
          font-size: 1rem;
        }
      }
    }
  }
}
