.footer_modal {
  display: flex;
  justify-content: space-evenly;
  .button_dat {
    width: 8rem;
    height: 3.6rem;
    background-color: #004dd1;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    border-radius: 10px;
    border: 0.2px solid gray;
    -webkit-tap-highlight-color: transparent; //bỏ nền xanh khi nhấn vào trên mobile

    &:hover {
      background-color: #aaaaaa;
    }

    i {
      color: #fff;
    }
  }
}
