.procedure_box {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  flex-wrap: wrap;
  padding-bottom: 18px;
  font-size: 1.8rem;

  .procedure_item {
    position: relative;
    width: 300px;
    margin-top: 50px;

    .procedure_square {
      width: 100%;
      height: 18rem;
      background: #fff;
      padding-top: 40px;
      border: 3px solid #241455;
      border-top-left-radius: 12%;
      border-top-right-radius: 12%;
      border-bottom-left-radius: 12%;

      h5 {
        text-align: center;
        position: absolute;
        top: 1rem;
        left: 12rem;
      }
      img {
        width: 80%;
        // margin: 10px 10px 0px 10px;
      }
    }

    .procedure_circle {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      left: -8rem;
      top: -35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: calc(300px / 2 - 70px / 2);
      border-top: 6px solid #241455;
      box-shadow: 0px 0px 5px 0px #999999;
      h3 {
        margin-top: 8px;
        color: #241455;
      }
    }
  }
}
