.nav_ul_destop {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.8rem;
  background-color: var(--color_main);
  margin: 0;
  height: 4.4rem;

  & > li {
    list-style: none;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0.08rem;
    color: #fff;

    &:hover {
      color: #647681;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: #fff;
      &:hover {
        color: #647681;
        cursor: pointer;
      }
    }
  }
  .nav_li_sanpham {
    position: relative;
    &:hover {
      .nav_ul_li_sanpham {
        display: block;
        z-index: 90;
      }
      .icon_down {
        color: #647681;
      }
    }

    .icon_down {
      font-size: 1.2rem;
      color: #fff;
      transform: translate(2px, -4px);
    }
  }
  .nav_li_banggia {
    position: relative;
    &:hover {
      .nav_ul_li_banggia {
        display: block;
        z-index: 90;
      }

      .icon_down {
        color: #647681;
      }
    }

    .icon_down {
      font-size: 1.2rem;
      color: #fff;
      transform: translate(0px, -4px);
    }
  }

  .nav_ul_li_sanpham,
  .nav_ul_li_banggia {
    display: none;
    position: absolute;
    top: 2rem;
    left: 0;
    text-align: left;
    color: #fff;
    font-size: 1.4rem;
    width: 25rem;
    background-color: var(--color_main);

    li {
      margin: 1rem;
      padding-bottom: 1rem;
      border-bottom: 0.1px solid rgba(0, 0, 0, 0.2);
      color: #fff;
      list-style: none;

      &:hover {
        color: #647681;
      }
    }
    li:last-child {
      border-bottom: none;
    }
    li:first-child {
      margin-top: 2rem;
    }
  }
}

//css cho nav mobile ------------------------------------
.nav_ul_mobile {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding: 0;
  position: fixed;
  // left: -100%;
  transform: translateY(-1px);
  width: 80%;
  height: 100%;
  text-align: left;
  background-color: var(--color_main);
  animation: widthgrow 0.12s linear;
  overflow: auto;

  @keyframes widthgrow {
    from {
      width: 0%;
    }
    to {
      width: 80%;
    }
  }

  // padding-top: 0.6rem;

  & > li {
    color: #aaaaaa;
    list-style: none;
    // border-bottom: 0.5px solid rgb(242, 242, 233);
    padding: 12px 0 12px 24px;
    min-width: 100%;
    box-sizing: border-box;
    cursor: pointer;

    &:first-child {
      margin-top: 1.2rem;
    }

    a {
      color: #aaaaaa;
      text-decoration: none;
    }
  }

  .nav_li_banggia,
  .nav_li_sanpham {
    padding: 12px 0 12px 24px;
    cursor: default;

    .icon_down {
      display: none;
    }
  }

  .nav_li_banggia li,
  .nav_li_sanpham li {
    margin-left: 2rem;
    padding: 0.8rem;
    color: #aaaaaa;
    border-bottom: 0.1px solid rgba(173, 169, 169, 0.5);
    list-style: none;
    cursor: pointer;

    &:first-child {
      margin-top: 1.2rem;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .img_main_mobile {
    // position: absolute;
    // top: 1.2rem;
    // left: 50%;
    width: 6.6rem;
    height: 6.6rem;
    border-radius: 50%;
  }
}

.nav_box {
  div {
    background-color: var(--color_main);
    text-align: center;
    animation: widthgrow backwards 0.12s;
    // .img_main {
    //   width: 5rem;
    //   height: 5rem;
    //   border-radius: 50%;
    //   margin: 0.6rem 0;
    // }

    @keyframes widthgrow {
      from {
        width: 0%;
      }
      to {
        width: 80%;
      }
    }
  }
}
