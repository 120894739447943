.head_mobile {
  position: fixed;
  background-color: var(--color_main);
  z-index: 100;
  width: 100%;

  img {
    width: 100%;
    height: 5rem;
  }

  .ve_home {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 35vw;
    -webkit-tap-highlight-color: transparent; //bỏ nền xanh khi nhấn vào trên mobile
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1004;

  .modal_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 122, 122, 0.7);
  }

  .modal_body {
    height: 100%;
    width: 80%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(255, 251, 251);
    animation: widthgrow 0.12s linear;

    @keyframes widthgrow {
      from {
        width: 0%;
      }
      to {
        width: 80%;
      }
    }

    .nav_box {
      background-color: var(--color_main);
      text-align: center;
      .img_main {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin: 0.6rem 0;
      }
    }
  }

  .icon_close {
    position: absolute;
    top: 1rem;
    left: 92%;
    font-size: 24px;
    color: rgb(228, 220, 220);
    animation: xlen alternate 0.6s;
  }

  @keyframes xlen {
    from {
      top: 2rem;
    }
    to {
      top: 1rem;
    }
  }
}

.hidden {
  display: none;
}

.icon_menu {
  font-size: 3rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--color_main);
  user-select: none;
}
