.slide_div {
  position: relative;
  img {
    width: 100%;
  }
}

.move_contact {
  z-index: 88;
  background-color: #fff;
  position: absolute;
  bottom: 6%;
  right: 9.8%;
  width: 16%;
  height: 16%;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: zoomContact 1s 8 alternate;
  @keyframes zoomContact {
    from {
      scale: 1;
    }
    to {
      scale: 1.2;
    }
  }

  div {
    flex: 2;
    text-align: left;
    p {
      &:first-child {
        margin-bottom: 2px;
      }
    }
  }

  a {
    width: 10%;
    flex: 1;

    i {
      transform: rotate(-45deg);
      color: #6cea67 !important;
    }

    img {
      margin-top: 0.4rem;
      width: 28%;
      height: 28%;
    }
  }
}

.hidden {
  display: none;
}
