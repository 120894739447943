.head_div_box {
  width: 100%;

  .img_head_box {
    background-color: var(--color_main);
    position: relative;

    .img-head {
      height: 100%;
      width: 100%;
    }
    .img_main {
      position: absolute;
      top: 1.2rem;
      left: 1.2rem;
      width: 9.4%;
      height: 79%;
      border-radius: 50%;
    }
  }
}
