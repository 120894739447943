body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Great Vibes', cursive;
  font-family: 'Open Sans', sans-serif; */
  font-family: 'Roboto Condensed', sans-serif;
  color: #333;
}

/*cái này sửa lỗi khi click vào Slider nó hiện cái khung, cũng như các css mà click nhiều lần bị xanh luôn */
*:focus {
  outline: 0;
  outline: none;
}

/* thêm cái này để tô đen các element thành màu đỏ, dùng phát hiện lỗi - scroll ngoài ý muốn */
/* * {
  outline: 1px solid #f00 !important;
  opacity: 1 !important;
  visibility: visible !important;
} */

html {
  font-size: 10px;
}

h1 {
  font-size: 3.6rem;
}

/* loại bỏ vạch trắn giữa 2 div */
body {
  font-size: 0;
}
div {
  font-size: 16px;
}
/* loại bỏ vạch trắng giữa 2 div */
:root {
  --color_main: #163244;
}

/* loại bỏ nền xanh khi nhấp vào icon đi lên của Antd trên mobile */
.css-dev-only-do-not-override-1wazalj {
  -webkit-tap-highlight-color: transparent !important;
}
