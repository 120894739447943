.notfound_box {
  // display: flex;
  // justify-content: center;
  // align-items: center;

  img {
    width: 100%;
  }

  p {
    margin-top: 2.4rem;
  }
}
